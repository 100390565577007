'use strict'

###*
 # @ngdoc object
 # @name mundoReporting
 # @description

###
angular
  .module 'mundoLive', [
    'angularMoment'
    'ngOrderObjectBy'
    'ui.router'
    'mundoUtils'
  ]
  .run [
    'MundoNavigation'
    (MundoNavigation) ->
      MundoNavigation.addSection
        id: 'live'
        state: 'live'
        label: 'app.report.live'
        icon: 'map'
        showExpression: 'hasPermission("access report section")'
        items: [
            state: 'live'
            label: 'app.report.live'
            icon: 'map'
            autoClose: false
            showExpression: 'hasPermission("access report section")'
        ]
  ]
